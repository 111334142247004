<template>
	<div id="asides">
		<el-aside id="aside" style="overflow-x: hidden;overflow-y: hidden">
		  <el-scrollbar class="scrollbar-wrapper" style="height:100%;overflow-x: hidden;">
		    <div >
		    <div style="color:#fff">
		         <el-image  :src="require('@/assets/images/111.png')" fit="contain"  style="width: 135px;margin: 20px auto 20px auto;display: block;"></el-image>
		    </div>
		    <el-menu class="el-menu-vertical-demo" router :default-active="activie_index" :unique-opened="true" :background-color="aside.aside_background_color"  text-color="#FFFFFF"  :active-text-color="aside.aside_active_text_color"  :collapse="isCollapse">
		      <template v-for="item in menu">
		        <el-submenu v-if="item.children && item.children.length" :index="item.path" :key="item.name">
		          <template slot="title" ><img :src="item.icon" style="margin-right: 10px;width: 16px;height: 16px;display: inline-block"><span>{{item.title}}</span></template>
		          <!-- 二级菜单 -->
		          <template v-for="itemChild in item.children">
		            <el-submenu v-if="itemChild.children && itemChild.children.length" :index="itemChild.path" :key="itemChild.name">
		                <template slot="title"><span>{{itemChild.title}}</span></template>
		                <!-- 三级菜单 -->
		                <el-menu-item v-for="itemChild_Child in itemChild.children" :index="itemChild_Child.path" :key="itemChild_Child.name">
		               <span slot="title">{{itemChild_Child.title}}</span></el-menu-item>
		            </el-submenu>
					  <div v-else>
						  <div v-if="adminIdentity==1">
							   <el-menu-item  :index="itemChild.path" :key="itemChild.name" v-if=" itemChild.title!='门店费用管理'"><span slot="title" >{{itemChild.title}}</span></el-menu-item>
						  </div>
						  <div v-else>
							   <el-menu-item  :index="itemChild.path" :key="itemChild.name" ><span slot="title" >{{itemChild.title}}</span></el-menu-item>
						  </div>
						  
						  
					  </div>
		           
		          </template>
		        </el-submenu>
				  <div v-else>
					  <el-menu-item  :index="item.path" :key="item.name"><img :src="item.icon" style="margin-right: 10px;width: 16px;height: 16px;display: inline-block"><span slot="title">{{item.title}}</span></el-menu-item>
					  
				  </div>
		       
		      </template>
		    </el-menu>
		    </div>
		  </el-scrollbar>
		</el-aside>
	</div>
 
</template>
<script>
import{ mapGetters } from 'vuex';
import { resetPwd  } from '@/api/admin/adminuser'

export default {
  name:'Side',
  computed: {
    ...mapGetters(['menu','sidebar','site_title']),
    isCollapse() {
      return !this.sidebar.opened
    }
    
  },

  data() {
    return {
      menuData:[],
	 roleType:localStorage.getItem('roleType'),
	 adminIdentity:localStorage.getItem('adminIdentity'),
      activie_index: this.$route.path,
      aside: {
        aside_background_color: '#1A2E40',
        aside_active_text_color: '#094693',
      },
    }
  },
  watch:{
    $route(){
      this.activie_index = this.$route.path
    }
  },
};
</script>
<style >
#asides  #aside{width: 150px !important;}
#asides .el-scrollbar__bar.is-horizontal{display: none;}
#asides .el-scrollbar__wrap{    margin-right: -16px !important;}
#asides .el-scrollbar__bar.is-vertical{width: 3px !important;}
#asides .el-scrollbar__view{overflow-x: hidden;}
.horizontal-collapse-transition { transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;}  
.el-menu {border-right: 0 !important;}
#aside::-webkit-scrollbar {display: none;}
#aside {height: 100vh; overflow-x: hidden;overflow-y: scroll;}
.el-submenu .el-menu-item{height: 40px; line-height: 40px;}
.el-menu-item, .el-submenu__title{height: 45px; line-height: 45px;}
/* .is-active{font-weight: bolder;font-size: 15px;} */
/* .is-active span{font-weight: bold;} */
 #asides .is-active{background-color: #094693 !important;color: #fff !important;font-size: 15px;}
</style>