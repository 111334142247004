import request from '@/api/request'
//运营人员列表---分页
export function index(params) {
	return request({ url: '/systemSetting/userList',method: 'get',data: params})
}
//添加运营人员账户
export function add(params) {
	return request({ url: '/systemSetting/addUser',method: 'post',data: params})
}

//用户禁用启用
export function disabledOpenUser(params) {
	return request({ url: '/systemSetting/disabledOpenUser',method: 'get',data: params})
}

//修改运营人员账户
export function update(params) {
	return request({ url: '/systemSetting/updateUser',method: 'post',data: params})
}

//批量删除用户
export function batchDel(params) {
	return request({ url: '/systemSetting/deleteUserBatch',method: 'post',data: params})
}

//删除单个用户
export function del(params) {
	return request({ url: '/systemSetting/deleteUserSingle',method: 'get',data: params})
}

//用户修改密码
export function changePass(params) {
	return request({ url: '/systemSetting/changePass',method: 'post',data: params})
}









